<template>
  <div class="activity" v-if="canView">
    <page-header
      :title="title"
      :icon="activityIcon"
      :links="getLinks()"
      :extra-links="getExtraLinks()"
    >
    </page-header>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div v-if="activity &&!editMode" v-show="!isLoading(loadingName)">
      <add-many-activity-dates-form
        v-if="addManyDates"
        :activity="activity"
        :existing-dates="activityDates"
        @cancel="addManyDates=false"
        @created="onManyDatesCreated($event)"
      >
      </add-many-activity-dates-form>
    </div>
    <b-row v-if="activity && !editMode" v-show="!isLoading(loadingName)">
      <b-col ref="excelMe" cols="9" class="activities-col" v-if="canView && !editMode">
        <div ref="printMe">
          <div :class="{ disabled: activity.disabled }" class="activity-title" :style="activity.getHeaderStyle()">
          <b-row>
            <b-col>
              <h2 style="display: inline-block">
                <span class="hide-here">{{ activity.name}} - </span>
                Agenda
              </h2>
            </b-col>
            <b-col cols="2" class="no-print">
              <span v-if="isForcePlaceActive">
                <a
                  class="btn-sm btn btn-danger"
                  href
                  @click.prevent="onDeletePlaces"
                >
                  <i class="fa fa-trash"></i> Supprimer
                </a>
              </span>
            </b-col>
            <b-col cols="2" class="text-right no-print">
              <span v-if="isForcePlaceActive">
                <a href @click.prevent="onChangePlace" class="btn btn-secondary btn-sm">
                  <i class="fa fa-location-dot"></i> Modifier le lieu
                </a>
              </span>
            </b-col>
          </b-row>
          </div>
          <agenda-dates-table
            :activity="activity"
            :show-header="false"
            :force-add-event="forceAddEvent"
            :force-change-places="forceChangePlace"
            :delete-change-places="deleteChangePlaces"
            :force-reload="forceReload"
            @forced="onForceDone()"
            @selected="onSelectionDone($event)"
            @loaded="activeDates = $event.dates"
          ></agenda-dates-table>
        </div>
      </b-col>

      <b-col cols="3" class="detail-col">
        <activity-info
          :activity="activity"
          :is-youth-home="isYouthHome"
          :force-dates-count="activeDates.length"
        ></activity-info>
      </b-col>
    </b-row>
    <activity-editor
      :activity="activity"
      :youth-home="isYouthHome ? 1 : 0"
      v-if="editMode"
      @done="onActivityUpdated($event)"
      @cancel="onEditCancelled($event)"
    ></activity-editor>
  </div>
</template>

<script>
// @ is an alias to /src
import router from '@/router'
import { mapMutations, mapActions } from 'vuex'
import AddManyActivityDatesForm from '@/components/Agenda/AddManyActivityDatesForm'
import AgendaDatesTable from '@/components/Agenda/AgendaDatesTable.vue'
import ActivityInfo from '@/components/Activities/ActivityInfo'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import ActivityEditor from '@/components/Activities/ActivityEditor'
import { BackendMixin } from '@/mixins/backend'
import { AgendaMixin } from '@/mixins/agenda'
import { makeActivity } from '@/types/activities'
import { BackendApi, openDocument } from '@/utils/http'
import { slugify } from '@/utils/strings'
import store from '@/store'

export default {
  name: 'activityDates',
  props: {
    activityId: String,
    youthHome: [String, Number],
  },
  mixins: [BackendMixin, AgendaMixin],
  components: {
    AgendaDatesTable,
    ActivityEditor,
    AddManyActivityDatesForm,
    LoadingGif,
    PageHeader,
    ActivityInfo,
  },
  data() {
    return {
      activity: null,
      loadingName: 'activityDates',
      activityDates: [],
      editEvent: null,
      addManyDates: false,
      editMode: false,
      activeDates: [],
    }
  },
  async created() {
    await this.loadData()
  },
  watch: {
    activityId: async function() {
      await this.loadData()
    },
    activity: function(newValue, oldValue) {},
    title: function() {
      store.dispatch('changeNav', { title: this.title, icon: store.getters.navIcon, })
    },
  },
  computed: {
    title() {
      if (this.isYouthHome) {
        return this.youthHomeLabel
      } else {
        return this.activity ? (this.activity.getCategoryName() + ' ' + this.activity.name) : ''
      }
    },
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    youthHomeIcon() {
      return store.getters.youthHomeIcon
    },
    activityIcon() {
      if (this.isYouthHome) {
        return this.youthHomeIcon
      } else {
        return 'fas fa-chess-knight'
      }
    },
    isEvent() {
      return this.activity && this.activity.isEvent()
    },
    isYouthHome() {
      return +this.youthHome !== 0
    },
    canView() {
      return this.hasPerm('agenda.view_activitydate')
    },
    canChange() {
      return this.hasPerm('agenda.change_activitydate')
    },
    canAdd() {
      return this.hasPerm('agenda.add_activitydate')
    },
    canChangeActivity() {
      if (this.isYouthHome) {
        return this.hasPerm('youth.change_youthhomeactivity')
      } else {
        return this.hasPerm('activities.change_coreactivity')
      }
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadData() {
      this.startLoading(this.loadingName)
      try {
        let url = '/api/activities/activities/' + this.activityId + '/'
        if (+this.youthHome) {
          url = '/api/youth/activities/' + this.activityId + '/'
        }
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.activity = makeActivity(resp.data)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    viewActivity() {
      let dest
      if (this.isYouthHome) {
        dest = { name: 'youth-homes-detail', params: { activityId: '' + this.activity.id, }, }
      } else {
        dest = { name: 'activities-detail', params: { activityId: '' + this.activity.id, }, }
      }
      router.push(dest)
    },
    async printMe() {
      let docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = 'dates-' + slugify(this.activity.name)
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getExtraLinks() {
      if (this.canView) {
        const ready = (!this.addManyDates && !this.editMode && !this.isLoading(this.loadingName))
        return [
          {
            id: 2,
            label: 'Pdf',
            callback: this.printMe,
            icon: 'fa fa-file-pdf',
            cssClass: ready ? 'btn-secondary' : 'btn-secondary disabled',
          },
          {
            id: 3,
            label: 'Excel',
            callback: this.excelMe,
            icon: 'fa fa-file-excel',
            cssClass: ready ? 'btn-secondary' : 'btn-secondary disabled',
          }
        ]
      } else {
        return []
      }
    },
    getLinks() {
      const links = []
      const ready = (!this.addManyDates && !this.editMode && !this.isLoading(this.loadingName))
      links.push(
        {
          id: 1,
          label: 'Inscriptions',
          callback: this.viewActivity,
          icon: this.activityIcon,
          cssClass: ready ? 'btn-secondary' : 'btn-secondary disabled',
        }
      )
      if (this.canChangeActivity) {
        links.push(
          {
            id: 6,
            label: 'Modifier',
            callback: this.editActivity,
            icon: 'fa fa-edit',
            cssClass: ready ? 'btn-secondary' : 'btn-secondary disabled',
          }
        )
      }
      if (this.canAdd) {
        links.push(
          {
            id: 4,
            label: 'Ajout',
            callback: this.onAddEvent,
            icon: 'fa fa-calendar-plus',
            cssClass: ready ? 'btn-secondary' : 'btn-secondary disabled',
          }
        )
        links.push(
          {
            id: 5,
            label: 'Ajout multiple',
            callback: this.onAddMany,
            icon: 'fa fa-calendar-plus',
            cssClass: ready ? 'btn-secondary' : 'btn-secondary disabled',
          }
        )
      }
      return links
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const activitySlug = slugify(this.activity.name)
      const docSlug = 'dates-' + activitySlug
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onAddMany() {
      this.addManyDates = true
    },
    onManyDatesCreated() {
      this.forceReload = true
      this.addManyDates = false
    },
    editActivity() {
      this.editMode = true
    },
    onActivityUpdated(event) {
      this.activity = event.activity
      this.editMode = false
    },
    onEditCancelled() {
      this.loadData()
      this.editMode = false
    },
  },
}
</script>

<style scoped lang="less">
</style>
