<template>
  <div class="add-many-dates" v-if="hasPerm('agenda.add_activitydate')">
    <b-form>
      <h3>Ajout de dates</h3>
      <div v-if="errors.length" class="errors">
        <div v-html="errorsHtml"></div>
      </div>
      <div class="field-line top-right-holder">
        <div class="top-right">
          <a href class="btn btn-sm btn-secondary" @click.prevent="selectAllDays">Tous</a>
          <a href class="btn btn-sm btn-secondary" @click.prevent="unselectAllDays">Aucun</a>
        </div>
        <b-form-group
          id="filter-group-weekday"
          label="Jours de la semaine"
          label-for="filter-weekday"
        >
          <div class="inline space-right" v-for="weekday in daysOfTheWeek" :key="weekday.id">
            <b-checkbox
              v-model="selectedWeekDays[weekday.id]"
              :name="weekday.name"
              :value="true"
              :unchecked-value="false"
            >
              {{ weekday.name }}
            </b-checkbox>
          </div>
        </b-form-group>
      </div>
      <loading-gif :loading-name="bankHolidaysLoadingName"></loading-gif>
      <div class="field-line top-right-holder" v-if="!isLoading(bankHolidaysLoadingName)">
        <div class="top-right">
          <a href class="btn btn-sm btn-secondary" @click.prevent="selectAllBankHolidays">Tous</a>
          <a href class="btn btn-sm btn-secondary" @click.prevent="unselectAllBankHolidays">Aucun</a>
        </div>
        <b-form-group
          id="filter-group-bank-holidays"
          label="Jours fériés et vacances scolaires"
          label-for="bank-holidays"
          description="Cocher les jours fériés et vacances pour lesquels vous souhaitez créer des dates"
        >
          <div class="inline space-right" v-for="elt in periodBankHolidays" :key="elt.id">
            <b-checkbox
              v-model="selectedBankHolidays[elt.id]"
              :name="'' + elt.id"
              :value="true"
              :unchecked-value="false"
            >
              <span v-b-tooltip="elt.period()">{{ elt.name }}</span>
            </b-checkbox>
          </div>
        </b-form-group>
      </div>
      <div class="field-line top-right-holder">
        <b-form-group
          id="filter-group-date-to-to"
          label="Date à date"
          label-for="filter-date-to-date"
        >
          <b-row>
            <b-col>
              <b>Du</b><br />
              <b-input
                name="date-from"
                v-model="dateFrom"
                type="date"
                @change="reset()"
              ></b-input>
              <div class="date-name">{{ dateFromWeekDay }}</div>
            </b-col>
            <b-col>
              <b>Au</b><br />
              <b-input
                name="date-to"
                v-model="dateTo"
                type="date"
                @change="reset()"
              ></b-input>
              <div class="date-name">{{ dateToWeekDay }}</div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="timeframe-warning" v-if="timeframeWarning">{{ timeframeWarning }}</div>
            </b-col>
          </b-row>
        </b-form-group>
      </div>
      <div>
        <b-row>
          <b-col cols="4">
            <b-form-group
              label-for="hasCustomPlace"
              label=""
            >
              <br />
              <b-form-checkbox id="hasCustomPlace" v-model="hasCustomPlace">
                Forcer un autre lieu
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label-for="place"
              label="Lieu"
              v-if="hasCustomPlace"
            >
              <b-select
                v-model="customPlace"
                name="customPlace"
              >
                <b-select-option v-for="place of allPlaces" :key="place.id" :value="place">
                  {{ place.name }}
                </b-select-option>
              </b-select>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label-for="ignoreIfExist"
              label="Options"
              v-if="hasCustomPlace"
              description="si décoché, une seule séance par jour est possible"
            >
              <b-form-checkbox id="ignoreIfExist" v-model="ignoreIfExist">
                Autoriser plusieurs séances le même jour
              </b-form-checkbox>
            </b-form-group>
          </b-col>
      </b-row>
      </div>
      <b-row class="buttons-bar">
        <b-col class="text-left" cols="6"></b-col>
        <b-col class="text-right" cols="6">
          <a class="btn btn-secondary" href @click.prevent="onCancel()">Annuler</a>
          <a
            class="btn btn-primary"
            :class="{'disabled': !isDataValid, }"
            href
            @click.prevent="onPreview()"
          >
            Prévisualiser
          </a>
        </b-col>
      </b-row>
    </b-form>
    <div v-if="showPreview" class="preview-list">
      <div class="preview-header">
        Dates à créer
      </div>
      <div class="preview-content">
        <loading-gif :loading-name="createDatesLoadingName"></loading-gif>
        <div v-if="!isLoading(createDatesLoadingName)">
          <div v-if="previewDates.length === 0">
            <i class="empty-text">Aucune date</i>
          </div>
          <div v-else>
            <b><counter-label label="date" :counter="previewDates.length"></counter-label></b>
            <b v-if="ignoredDates.length">
              dont
              <counter-label label="ignorée" :counter="ignoredDates.length"></counter-label>
            </b>
          </div>
        </div>
        <div
          v-for="date of previewDates"
          :key="date.key()"
          class="preview-line"
          :class="{ 'existing': date.id > 0 && !date.changeWarning, 'ignored': isDateIgnored(date)}"
        >
          <b-row>
            <b-col>
              {{ date.startDateTime | dateToString('ddd ll HH:mm') }}
              {{ date.endDateTime | dateToString('HH:mm') }}
              <div v-if="date.dayComments" class="badge badge-normal-wrap badge-warning badge-small-text">
                {{ date.dayComments }}
              </div>
            </b-col>
            <b-col>
              {{ date.getLabel() }}
            </b-col>
            <b-col>
              {{ date.place.name }}
            </b-col>
            <b-col class="text-right" cols="3">
              <div v-if="date.id">
                <div v-if="date.changeWarning" class="badge badge-normal-wrap badge-warning badge-small-text">
                  {{ date.changeWarning }}
                </div>
                <div v-else class="badge badge-light">
                  Existe déjà
                </div>
              </div>
              <div
                v-else-if="date.changeWarning"
                class="badge badge-normal-wrap badge-small-text"
                :class="isConflictIgnored(date) ? 'badge-error' : 'badge-warning'"
              >
                <span v-if="date.conflict && isConflictIgnored(date)">
                  La date sera créée malgré le conflit
                </span>
                <span v-else>
                  {{ date.changeWarning }}
                </span>
                &nbsp;
                <a v-if="date.conflict" class="btn btn-sm btn-secondary" href @click.prevent="ignoreConflict(date)">
                  <i class="fa fa-times"></i>
                </a>
              </div>
              <div v-else>
                <a class="btn btn-sm btn-secondary" href @click.prevent="ignoreDate(date)">
                  <i class="fa fa-times"></i>
                </a>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-row class="buttons-bar">
          <b-col class="text-right" cols="12">
            <a
              :class="{ 'disabled': previewDates.length === 0, }"
              class="btn btn-primary"
              href
              @click.prevent="onCreate()"
            >
              Créer les dates
            </a>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapMutations, mapActions } from 'vuex'
import CounterLabel from '@/components/Controls/CounterLabel'
import LoadingGif from '@/components/Controls/LoadingGif'
import { BackendMixin } from '@/mixins/backend'
import { makeActivityDate } from '@/types/agenda'
import { makeBankHoliday } from '@/types/schools'
import { BackendApi } from '@/utils/http'
import { makePlace } from '@/types/base'

export default {
  name: 'AddManyActivityDatesForm',
  components: {
    CounterLabel,
    LoadingGif,
  },
  mixins: [BackendMixin],
  props: {
    activity: Object,
    existingDates: Array,
  },
  data() {
    return {
      selectedWeekDays: [],
      dateFrom: null,
      dateTo: null,
      previewDates: [],
      createDatesLoadingName: 'addManyDates',
      bankHolidaysLoadingName: 'allBankHolidays',
      showPreview: false,
      bankHolidays: [],
      selectedBankHolidays: {},
      ignoredCodes: {},
      ignoredConflicts: {},
      periodsDateFrom: null,
      periodsDateTo: null,
      errors: [],
      allPlaces: [],
      customPlace: null,
      hasCustomPlace: false,
      ignoreIfExist: false,
    }
  },
  created() {
    this.selectedWeekDays = this.weekDays || [ ...Array(7).keys() ].map(elt => true)
    this.setTimeframe()
    this.setTypeDays()
    this.loadBankHolidays()
    this.loadAllPlaces()
  },
  computed: {
    periodBankHolidays() {
      if (this.dateFrom && this.dateTo) {
        return this.bankHolidays.filter(
          elt => {
            // TODO endDate
            return ((elt.date >= this.dateFrom) && (elt.date <= this.dateTo))
          }
        )
      } else {
        return []
      }
    },
    ignoredDates() {
      return this.previewDates.filter(
        elt => this.isDateIgnored(elt)
      )
    },
    daysOfTheWeek() {
      return [ ...Array(7).keys() ].map(
        index => {
          return { id: index, name: moment.weekdays(true, index), }
        }
      )
    },
    dateFromWeekDay() {
      if (this.dateFrom) {
        return moment(this.dateFrom).format('dddd LL')
      }
      return '-'
    },
    dateToWeekDay() {
      if (this.dateTo) {
        return moment(this.dateTo).format('dddd LL')
      }
      return '-'
    },
    isDataValid() {
      let isValid = (
        this.dateFrom && this.dateTo && this.dateFrom <= this.dateTo
      )
      if (isValid && this.hasCustomPlace && !this.customPlace) {
        isValid = false
      }
      return isValid
    },
    timeframeWarning() {
      const warnings = []
      if ((this.periodsDateFrom) && (this.dateFrom < this.periodsDateFrom)) {
        warnings.push('La date de début précède celle des périodes sélectionnées.')
      }
      if ((this.periodsDateTo) && (this.dateTo > this.periodsDateTo)) {
        warnings.push('La date de fin dépasse celle des périodes sélectionnées.')
      }
      if (warnings.length) {
        warnings.push('Seules seront créées les séances dont la date correspond à une période.')
      }
      return warnings.join(' ')
    },
    errorsHtml() {
      return this.errors.join('<br />')
    },
  },
  watch: {
    selectedWeekDays: function() { this.reset() },
    selectedBankHolidays: {
      handler: function() {
        this.reset()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    selectAllDays() {
      let values = this.selectedWeekDays.filter(elt => true)
      for (let index = 0; index < values.length; index++) {
        values[index] = true
      }
      this.selectedWeekDays = values
    },
    unselectAllDays() {
      let values = this.selectedWeekDays.filter(elt => true)
      for (let index = 0; index < values.length; index++) {
        values[index] = false
      }
      this.selectedWeekDays = values
    },
    selectAllBankHolidays() {
      for (let elt of this.periodBankHolidays) {
        this.selectedBankHolidays[elt.id] = true
      }
      this.selectedBankHolidays = { ...this.selectedBankHolidays, }
    },
    unselectAllBankHolidays() {
      for (let elt of this.periodBankHolidays) {
        this.selectedBankHolidays[elt.id] = false
      }
      this.selectedBankHolidays = { ...this.selectedBankHolidays, }
    },
    setTypeDays() {
      if (this.activity.day) {
        const activityDay = this.activity.day.toLowerCase()
        const selection = []
        for (const day of this.daysOfTheWeek) {
          selection.push(activityDay.indexOf(day.name) >= 0)
        }
        this.selectedWeekDays = selection
      }
    },
    setTimeframe() {
      if (this.activity) {
        this.dateFrom = this.activity.startDate || this.activity.schoolYear.startDate
        this.dateTo = this.activity.schoolYear.endDate
        this.periodsDateFrom = this.dateFrom
        this.periodsDateTo = this.dateTo
      }
    },
    setDateTo(field, setter) {
      let dateValue = (field === 'from') ? this.dateFrom : this.dateTo
      let otherValue = (field === 'from') ? this.dateTo : this.dateFrom
      let now = moment()
      if (!dateValue) {
        if (field === 'to' && otherValue) {
          dateValue = moment(otherValue)
        } else {
          dateValue = now.clone()
        }
      } else {
        dateValue = moment(dateValue)
      }
      if (!otherValue) {
        otherValue = dateValue.clone()
      } else {
        otherValue = moment(otherValue)
      }
      if (field === 'from') {
        this.dateFrom = dateValue.format('YYYY-MM-DD')
      } else {
        this.dateTo = dateValue.format('YYYY-MM-DD')
      }
    },
    reset() {
      this.previewDates = []
      this.showPreview = false
    },
    onCancel() {
      this.$emit('cancel', {})
    },
    getData() {
      let holidays = []
      for (let bankHoliday of this.periodBankHolidays) {
        if (
          !this.selectedBankHolidays.hasOwnProperty(bankHoliday.id) ||
          !this.selectedBankHolidays[bankHoliday.id]
        ) {
          holidays.push(bankHoliday.id)
        }
      }
      return {
        activities: [this.activity.id],
        custom_place: this.customPlace ? this.customPlace.id : null,
        ignore_if_exists: this.ignoreIfExist,
        custom_start_time: null,
        custom_end_time: null,
        date_from: this.dateFrom,
        date_to: this.dateTo,
        week_days: this.daysOfTheWeek.map(elt => elt.id).filter(elt => this.selectedWeekDays[elt]),
        holidays: holidays,
      }
    },
    async loadAllPlaces() {
      try {
        let url = '/api/home/places/'
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.allPlaces = resp.data.map(elt => makePlace(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadDates(preview) {
      this.errors = []
      if (this.activity) {
        this.startLoading(this.createDatesLoadingName)
        let url = '/agenda/api/activity-dates/create_many/'
        let backendApi = new BackendApi('post', url)
        try {
          let data = this.getData()
          data.preview = preview
          if (!preview) {
            data.ignored_dates = this.previewDates.filter(
              date => this.isDateIgnored(date)
            ).map(date => moment(date.startDateTime).format('YYYY-MM-DDTHH:mm:00'))
            data.ignored_conflicts = this.previewDates.filter(
              date => date.conflict && this.isConflictIgnored(date)
            ).map(date => moment(date.startDateTime).format('YYYY-MM-DDTHH:mm:00'))
          }
          let resp = await backendApi.callApi(data)
          let dates = resp.data.dates.map(elt => makeActivityDate(elt))
          this.errors = resp.data.errors
          if (preview) {
            this.previewDates = dates
          } else {
            this.previewDates = []
            let message = ''
            if (dates.length === 0) {
              message = 'Aucune date n\'a été créée'
            } else if (dates.length === 1) {
              message = 'Une date a été créée'
            } else {
              message = '' + dates.length + ' dates ont été créées'
            }
            await this.addSuccess(message)
            this.$emit('created', dates)
          }
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.createDatesLoadingName)
      }
    },
    async loadBankHolidays() {
      if (this.activity) {
        this.startLoading(this.bankHolidaysLoadingName)
        let url = '/api/bank-holidays/' + this.activity.schoolYear.id + '/?include_school=1'
        let backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          this.bankHolidays = resp.data.map(elt => makeBankHoliday(elt))
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.bankHolidaysLoadingName)
      }
    },
    onPreview() {
      this.showPreview = true
      if (this.isDataValid) {
        this.loadDates(true)
      }
    },
    onCreate() {
      this.loadDates(false)
    },
    ignoreDate(date) {
      const code = date.key()
      if (this.ignoredCodes.hasOwnProperty(code)) {
        this.ignoredCodes[code] = !this.ignoredCodes[code]
      } else {
        this.ignoredCodes[code] = true
      }
      this.ignoredCodes = { ...this.ignoredCodes, }
    },
    isDateIgnored(date) {
      const code = date.key()
      if (this.ignoredCodes.hasOwnProperty(code)) {
        return this.ignoredCodes[code]
      }
      return false
    },
    ignoreConflict(date) {
      const code = date.key()
      if (this.ignoredConflicts.hasOwnProperty(code)) {
        this.ignoredConflicts[code] = !this.ignoredConflicts[code]
      } else {
        this.ignoredConflicts[code] = true
      }
      this.ignoredConflicts = { ...this.ignoredConflicts, }
    },
    isConflictIgnored(date) {
      const code = date.key()
      if (this.ignoredConflicts.hasOwnProperty(code)) {
        return this.ignoredConflicts[code]
      }
      return false
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.add-many-dates {
  padding: 10px;
  background: #e0e0e0;
  margin-bottom: 10px;
}
.inline {
  display: inline-block;
}
.space-right {
  margin-right: 25px;
}
.space-right .custom-control {
}
.top-right-holder {
  position: relative;
}
.top-right {
  position: absolute;
  right: 5px;
  top: 0;
}
.top-right a {
  margin-left: 5px;
}
.preview-list {
  margin-top: 20px;
  border: solid 1px #444;
  border-radius: 4px;
}
.preview-header {
  margin-bottom: 5px;
  border-bottom: solid 1px #444;
  font-weight: bold;
  padding: 5px;
}
.preview-content {
  padding: 5px;
}
.preview-line {
  padding: 3px;
}
.preview-line:nth-of-type(even) {
  background: #f0f0f0;
}
.preview-list .existing {
  color: #aaa;
  font-style: italic;
}
.preview-list .ignored {
  color: #aaa;
  text-decoration: line-through;
}
.preview-list .buttons-bar {
  margin-top: 10px;
}
.date-setters .btn {
  min-width: 30px;
  padding: 2px;
  margin: 2px;
}
.date-name {
  font-size: 12px;
  color: #444;
}
.timeframe-warning, .warning-message {
  background: #ffb871;
  color: #000;
  font-size: 12px;
  padding: 3px;
}
.errors {
  padding: 10px;
  background: #e6625e;
  font-weight: bold;
  margin-bottom: 10px;
}
</style>
