import { render, staticRenderFns } from "./AddManyActivityDatesForm.vue?vue&type=template&id=65e7d95e&scoped=true&"
import script from "./AddManyActivityDatesForm.vue?vue&type=script&lang=js&"
export * from "./AddManyActivityDatesForm.vue?vue&type=script&lang=js&"
import style0 from "./AddManyActivityDatesForm.vue?vue&type=style&index=0&id=65e7d95e&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65e7d95e",
  null
  
)

export default component.exports